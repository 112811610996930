import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getUserLearnPlan = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-learn-plan/get?id=${id}`);
};

///api/itedu/v1/tiku/question/get
const addEdit = (item) => {
  return ajax.post(`${base}/api/itedu/v1/user-learn-plan/add-edit`, item);
};

const getAidaUser = (phone) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-learn-plan/aida-user/config?phone=${phone}`
  );
};

const getAidaUserById = (userId) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-learn-plan/aida-user/get?userId=${userId}`
  );
};

const queryUserLearnPlan = (userId, courseId, startTime, endTime) => {
  return ajax.get(
    `${base}/api/itedu/v1/user-learn-plan/query?userId=${userId}&courseId=${courseId}&startTime=${startTime}&endTime=${endTime}`
  );
};

///api/itedu/v1/user-learn-plan/cos/url
const getCdnUrl = (key) => {
  return ajax.get(`${base}/api/itedu/v1/user-learn-plan/cos/url?key=${key}`);
};

const deleteCos = (key) => {
  return ajax.post(
    `${base}/api/itedu/v1/user-learn-plan/cos/delete?key=${key}`
  );
};

export const userLearnPlanApi = {
  addEdit: addEdit,
  getAidaUser: getAidaUser,
  getCdnUrl: getCdnUrl,
  deleteCos: deleteCos,
  queryUserLearnPlan: queryUserLearnPlan,
  getUserLearnPlan: getUserLearnPlan,
  getAidaUserById: getAidaUserById,
};
